import React,{useEffect, useRef} from 'react'
import '../styles/workStyles.scss'
import {gsap} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AboutOverlay from '../components/Overlays/AboutOverlay'
import {Link, navigate} from 'gatsby'
import {ArrowTop} from '../assets/social-icons'
import {
    useGlobalDispatchContext,
  } from '../context/globalContext'

  
  


gsap.registerPlugin(ScrollTrigger);

const WorkPage = () => {

    const dispatch = useGlobalDispatchContext()

  
    const onCursor = cursorType => {
        dispatch({ type: "CURSOR_TYPE", cursorType: cursorType })
    }



    const imageOverlay1 = useRef(null)
    const image1 = useRef(null)
    const imageContainer = useRef(null)
    const year1 = useRef(null)
    const headline1 = useRef(null)
    const year2 = useRef(null)
    const headline2 = useRef(null)
    const imageOverlay2 = useRef(null)
    const image2 = useRef(null)

    //Main timeline
    useEffect(() => {

        onCursor()
         //timeline
        const tl = gsap.timeline()
        tl.to('body',{
            pointerEvents:'none',
        })
        .to(".about-front-1", {
          height: 0,
          ease: "Power2.easeOut",
          stagger: 0.2,
          delay:  .3,
          duration:  1.3,
        })
          .to(".about-back-1", {
            duration: 0.8,
            height: 0,
            ease: "Power3.easeOut",
            stagger: 0.2,
            delay: -0.7,
          })
          .to('.about-middle-1',{
          height: 0,
          ease: "Power2.easeOut",
          stagger: 0.2,
          delay:  -.4,
          duration: .2,
          })
          .to(".about-intro-overlay", { css: { display: "none" } })
          .from(imageOverlay1.current, {
              duration:.6,
              height:'100%',
          })
          .from([headline1.current,year1.current], {
              duration:.4,
              opacity:0,
              y:20,
              stagger:{
                  amount:.2
              }
          })
          .to('body',{
              pointerEvents:'all',
          })
      }, [])

     //Hover animations 
       const handleMouseEnter = (node1) => {
          gsap.to(node1,{
              duration:.4,
              scale:1.2
          })
      }      
      const handleMouseOut = (node1) => {
        gsap.to(node1,{
            duration:.4,
            scale:1
        })
      }  
      //Scroll to top
      const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
   //ScrollTrigger animation
   useEffect(()=> {
    const tl1 = gsap.timeline()

        ScrollTrigger.create({
            trigger: imageContainer.current,
            animation: tl1,
            markers: false,
            start: "center",
        });

    
        tl1.from(imageOverlay2.current, {
            duration:1,
            ease:'Power3.easeOut',
            height:'100%'
        })
        .from([headline2.current,year2.current],{
              duration:.5,
              opacity:0,
              y:20,
              stagger:{
                  amount:.2
              }
        })
   }, []) 

   const handleClickFirstProject = (e) => {
       e.preventDefault()
       gsap.to('.projects-container',{
        delay: .3,
        duration:1,
        opacity:0,
        y:-100
    })
        setTimeout(() => {
           navigate('/hair-salon')
       }, 2000); 
   }
   //Back to home
   const navigateHome = (e) => {
       e.preventDefault()
       gsap.to('.projects-container',{
           delay: .3,
           duration:1,
           opacity:0,
           y:-100
       })
       setTimeout(() => {
        navigate('/')
       }, 1200);
   }

   

    return (
        <>
        <AboutOverlay />
       <div className="projects-container">
            <div className="work-banner">
                <div className="redirect">
                        <ArrowTop/>
                        <Link to='/' onClick={navigateHome} onMouseEnter={()=> onCursor('pointer')} onMouseOut={()=>onCursor()}>Back to home</Link>
                </div>
                <p><span>02</span> - Work</p>
            </div>
            <div className="project-line"></div>
            {/* FIRST PROJECT */}
            <div className="project first">
                <div className="content">
                    <div className="content-details">
                        <p ref={year1}>2020</p>
                        <div className="headline">
                            <Link to='/' onClick={handleClickFirstProject}>
                             <h3 ref={headline1} 
                                 onMouseEnter={()=>{
                                    handleMouseEnter(image1.current)
                                    onCursor('hovered')
                                }}
                                onMouseOut={()=>{
                                    handleMouseOut(image1.current)
                                    onCursor()
                                }} 
                               >
                                  Hair Salon Landing Page
                                </h3>
                            </Link>
                        </div>     
                    </div>
                    
                </div>
                <div className="content-image"
                ref={imageContainer}>
                    <div className="image-overlay" ref={imageOverlay1}></div>
                    <img src={require('../images/img1.jpg')} ref={image1}/>
                </div>
            </div>
            {/* SECOND PROJECT */}
            <div className="project last">
                 <div className="content-image">
               
                    <div ref={imageOverlay2} className="image-overlay"></div>
                    <img src={require('../images/img2.jpg')} ref={image2}/>
                </div>
                <div className="content left">  
                    <div className="content-details">
                        <p ref={year2}>2020</p>
                        <div className="headline">
                        <Link to='/' /* onClick={handleClick} */>
                            <h3 ref={headline2}
                            onMouseEnter={()=>handleMouseEnter(image2.current)}
                            onMouseOut={()=>handleMouseOut(image2.current)}>
                            Headline</h3>
                        </Link>                            
                       </div>
                    </div>
                </div>
               
            </div>
            {/* THIRD PROJECT */}
        <div className="back-to-top" onClick={scrollTop}  onMouseEnter={()=> onCursor('pointer')} onMouseOut={()=>onCursor()}>
            <p>Back to top</p>
            <ArrowTop />
        </div> 
       </div>
        </>
    )
}

export default WorkPage
